@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

.ag-custom-theme-tight-users {
  @include ag-theme-alpine(
    (
      grid-size: 5px,
      row-height: ag-derived(grid-size, $times: 6, $plus: 0),
      font-size: 11px,
      header-height: ag-derived(row-height, $times: 1, $plus: 4),
    )
  );
}
